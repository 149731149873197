import { Link } from "react-router-dom";
import Heading from "../../layout/Heading";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { usePageTitle } from "../../common/PageTitle";
import H2Title from "../../layout/H2Title";
import img from "../../../images/kikki-bio-image.jpg"

export default function Kontakt() {
    const [page_title, setPageTitle] = usePageTitle("Biography | Kikki Aikio ");
    return (
        <main>
            <Container className="wrapper">
                <Heading title="Biography" />
                <div className="flex_row_container">
                    <div className=" flex_row_child">
                        <img className="image, flex_contained_image" src={img} alt="Kikkio Aikio wearing a traditional sami outfil with the sea in the background."/>
                    </div>
                    <div className=" flex_row_child, row_margin ">
                        <p className="row_flex_text">In 2023, the Christian Sámi singer songwriter Kikki Aikio celebrates 20 years as a recording artist with numerous albums in Sámi, English and Norwegian.</p>
                        <p className="row_flex_text">Kikki Aikio is a multi-talented singer-songwriter whose music is inspired by her Christian faith and her Sami roots. Kikki was born in Tromsø in Northern Norway. Her love for music started early and she has been singing since she was a child. She began writing her own songs as a teenager and has since released several albums in a variety of genres - including a children's album. She has established herself a well-known artist in the Sami community.</p>
                        <p className="row_flex_text">Kikki's music is characterized by her powerful voice and heartfelt lyrics. Her music is a testament to her passion for both her faith and her culture. Her lyrics are often fictional, exploring themes of faith, love, and the natural world. She often sings in the Sami language, which is the language of the indigenous people of northern Scandinavia, but also includes songs in Norwegian and English. Kikki's music is inspired by her faith, and she is known for her uplifting and positive message.
                        </p>
                        <p className="row_flex_text">Kikki releases all her albums under her own record label Nival Records.</p>
                    {/* </article> */}
                    </div>
                </div>
            </Container>
        </main>
    );
}