import { Link } from "react-router-dom";
import Heading from "../../layout/Heading";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import { usePageTitle } from "../../common/PageTitle";
import H2Title from "../../layout/H2Title";
import img from "../../../images/bio-image.jpg"

export default function Kontakt() {
    const [page_title, setPageTitle] = usePageTitle("Kontakt Kikki Aikio | Sami musician");
    return (
        <main>
            <Container className="wrapper">
                <Heading title="Contact" />
                <div className="flex_row_container">
                    <div className=" flex_row_child, row_margin">
                        <H2Title title="Get in touch with me" />
                        <p className="row_flex_text">Please don't hesitate to get in touch with me if you have any questions or requests. Send me an email, and I will contact you as soon as possible.</p>
                    {/* <p>Kikki Aikio</p> */}
                        <p><span className="kontakt"><span className="bold">Email:</span> kikki@kikkiaikio.no</span></p>
                    </div>
                    <div className=" flex_row_child, flex_child_image">
                        <img className="image, flex_contained_image" src={img} alt="Kikkio Aikio sitting on a rock near the sea"/>
                    </div>
                </div>
            </Container>
        </main>
    );
}